import { CircleBoldIcon } from '@libs/ui/ds/assets/icons'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Center } from '../../../../primitiveLayouts/Center/base/Center'

import type { RadioButtonInputProps } from './RadioButtonInput.types'

const checkboxInputCVA = {
  root: cva([]),
  input: cva(['peer/input hidden']),
  outerBox: cva([
    'size-4 min-h-4 min-w-4 ',
    'inline-flex rounded-full',
    'border-1 border-neutral-200 bg-neutral-50 [&>*]:invisible',
    /**
     * Checked state
     */
    'peer-checked/input:border-none peer-checked/input:[&>*]:visible',
    ' peer-checked/input:bg-brand-green-700 peer-checked/input:text-neutral-50',
    /**
     * Disabled state
     */
    'peer-disabled/input:border-1 peer-disabled/input:border-solid peer-disabled/input:border-neutral-400',
    'peer-checked/input:bg-brand-green-700 peer-checked/input:text-neutral-50',
    /**
     * Checked + Disabled state
     */
    'peer-checked/input:peer-disabled/input:border-neutral-400 peer-checked/input:peer-disabled/input:bg-neutral-400'
  ])
}

export const RadioButtonInput = forwardRef<
  HTMLInputElement,
  RadioButtonInputProps
>(({ disabled, checked, required, onChange, name, value, ...other }, ref) => {
  return (
    <div className={checkboxInputCVA.root()}>
      <input
        {...other}
        name={name}
        required={required}
        disabled={disabled}
        checked={checked}
        value={value}
        onChange={onChange}
        ref={ref}
        type='radio'
        className={checkboxInputCVA.input()}
      />
      <Center className={checkboxInputCVA.outerBox()}>
        <IconWrap icon={<CircleBoldIcon />} size='xs' />
      </Center>
    </div>
  )
})
